@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /*
  IE10+ specific styles go here
  make it super specific so it overrides other styles
  */
  html {
    body {
      .main-template__home-screen .content .container,
      .main-template__sign-in-screen .content .container ,
      .main-template__issue-detail-screen .content .container,
      .main-template__new-issue-screen .content .container,
      .main-template__service-request-screen .content .container {
        margin: 0;
        padding-top: 40px;
        padding-bottom: 40px;
      }
      .DateInput_input,
      .input,
      .react-autosuggest__input ,
      .DateInput_input.input--large,
      .input.input--large,
      .react-autosuggest__input.input--large {
        padding-top:20px;
        padding-bottom:20px;
      }
      .issue-search-form__input, .issue-search-form__input.input {
        padding-top:25px;
        padding-bottom:25px;
        line-height: 1em;
      }
      .language-picker select {
        font-size: 0px;
        display:block;
        line-height: 0px;
      }
    }
  }
}
