@import './variables';

textarea {
  width: 100%;
  resize: none;
  padding: 15px;
  overflow: auto;
}

// .inputbox {
//   width: 100%;
//   padding-left: 10px;
//   padding: 0 1em;
//   -webkit-appearance: button;
//   line-height: $input-height;
// }

// textarea,
// .inputbox {
//   border: 1px solid $form-border-color;
//   border-radius: 4px;
//   font-size: 16px;
//   font-weight: 400;
//   text-indent: 0.01px;
//   margin: 0;
//   outline: 0;
//   display: block;
//   background-color: #fff;
//   color: #424242;
//   font-size: 22px;
//   border-color: #fff;
//   border-radius: 0;
//   border: 2px solid #000;
//   &:focus {
//     border-color: transparent;
//     box-shadow: 0px 0px 0px 4px #077284;
//   }
// }
