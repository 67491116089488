@import 'variables';
@import 'typography';
@import 'mixins';
@import 'forms';
@import 'ie11';

html,
body {
  font-size: $root-font-size;
  height: 100%;
  width: 100%;
  background-color: #fff;
  // overflow: hidden;
}

html {height:100%}
body {min-height:100%}


#root {
  height: inherit;
  min-height: 100%;
}

.root__navigation {
  background-color: #fff;
  background: #2653ca;
  z-index: 2;
}

.root__content {
  position: relative;
  width: 100%;
  height: 100%;
  // overflow-x: hidden;
  // overflow-y: scroll;
  // padding-top:90px;
  z-index: 1;
  @media (min-width: 0px) and (max-width: 700px) {
    margin-left: 0;
    width: 100%;
    height: 100%;
    padding-top: 180px;
  }
}

.max-width {
  width: $max-width;
  padding: 0;
  max-width: 100vw;
  margin: 0 auto;
  @media (min-width: 0px) and (max-width: 900px) {
    display: block;
    width: 100%;
    padding: 20px;
  }
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'inter-ui';
  font-weight: 400;
}

.section-header {
  color: $color-title;
  color: #424242;
  color: #112b4e;
  font-size: 22px;
  font-weight: 600;
  line-height: 38px;
  text-transform: capitalize;
  width: 100%;
  letter-spacing: -0.3px;
  margin: 0;
}

.section-subheader,
.section-label {
  color: $color-title;
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  overflow-wrap: break-word;
  span.hint {
    display: block;
    font-weight: 400;
    line-height: 1.5;
    font-size: 17px;
    margin-top: 2px;
    color: #646271;
    max-width: 650px;
  }
  span.time {
    padding-left: 20px;
    display: block;
    @media (min-width: 990px) {
      display: inline;
    }
  }
}

.section-subheader--inprogress {
  color: #25b429;
}

hr {
  border: 0;
  border-top: 1px solid $hr-color;
  margin: 50px 0 0;
}

.mt-40 {
  margin-top: 40px;
}

.section-thumbnails {
  width: 57px;
}

.navbar-brand-issue {
  color: #000;
  font-size: 14px;
  @media (min-width: 990px) {
    font-size: 22px;
  }
}

.btn__btnback {
  display: inline-flex;
  .btn {
    padding: 0px;
    min-width: 50px;
    @media (min-width: 991px) {
      padding: 4px;
      min-width: 100px;
    }
  }
}

.text-danger {
  display: block;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  top: -20px;
}

.col-50 {
  @media (min-width: 576px) and (max-width: 767px) {
    max-width: 49.5% !important;
    flex: 0 0 49.5%;
  }
}

.DateInput_input__focused {
  border: none !important;
}

.DateInput_fang {
  top: 48px !important;
}

/* IE11 Hack */
*::-ms-backdrop,
.DateInput_fang {
  top: 44px !important;
}

.page-header {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 15px;
  letter-spacing: -0.4px;
}

.page-subheader {
  color: #ffffffb5;
  font-size: 18px;
  font-weight: 400;
}

.paper-container {
  margin-top: 25px;
  background-color: white;
  border-radius: 4px;
  padding: 20px 15px;
  position: relative;
  text-decoration: none;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
  padding: 0;
  overflow: hidden;
}

.paper-container-header {
  background: #d5dae4;
  display: block;
  font-weight: 600;
  padding-left: 24px;
  margin: 0;
  h3 {
    line-height: 54px;
    font-size: 18px;
    color: #00000080;
    margin: 0;
    padding: 0;
  }
}

.form-section {
  padding: 20px 25px;
}

.textarea-fadein {
  animation: textareaEntryAnimation 400ms;
}

@keyframes textareaEntryAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.paper {
  background: #eeeeee;
  // background: #fff;
  // border: 1px solid rgba(0, 0, 0, 0.1);
  // border-radius: pxToRem(6px);
  margin: 0 auto 30px auto;
  width: 100%;
  // max-width: pxToRem(580px);
  padding: pxToRem(35px) pxToRem(40px);
  // box-shadow: 0px 4px 10px -5px rgba(0, 0, 0, 0.15);
  h3 {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.5px;
  }
  h1 {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: pxToRem(36px);
    letter-spacing: -0.5px;
    margin-bottom: pxToRem(12px);
  }
  h2 {
    color: #939598;
    font-weight: 400;
    font-size: pxToRem(18px);
    display: block;
    text-align: center;
    margin-bottom: pxToRem(30px);
  }
  @media (max-width: 580px) {
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding: pxToRem(20px) pxToRem(15px);
    h1 {
      text-align: left;
      font-size: pxToRem(26px);
      font-weight: 600;
      letter-spacing: -0.5px;
      margin-bottom: pxToRem(8px);
    }
    h2 {
      text-align: left;
      font-size: pxToRem(15px);
      margin-bottom: pxToRem(30px);
    }
  }
}

button.submit-button {
  background-color: #132e71;
  border: 0;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  line-height: 56px;
  outline: none;
  padding: 0 32px;
  text-decoration: none;

  max-width: 250px;
  margin: 30px 0 0 0;
  background-color: #5de8c1;
  background-color: #077284;
  border-radius: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.sub-input-container {
  padding-left: 20px;
  margin-left: 12px;
  border-left: 1px solid #2e5fdd;
  margin-bottom: 30px;
}

.left-right-form-items {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  > div {
    width: 50%;
  }
  > div:nth-child(1) {
    padding-right: 25px;
  }
  > div:nth-child(2) {
    padding-left: 25px;
  }
  @media (min-width: 0px) and (max-width: 700px) {
    display: block;
    > div {
      width: 100%;
    }
    > div:nth-child(1) {
      padding-right: 0;
    }
    > div:nth-child(2) {
      padding-left: 0;
    }
  }
}

.radio__option-label,
.checkbox__option-label {
  color: $color-title;
}


.hamburger-menu {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  i {
    color: #fff;
    font-size: 30px;
  }
}




.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  min-height: 100%;
}

.content .container {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // align-content: center;
}

.content {
  flex: 1 1 auto;
  width: 100%;
  min-height: auto;
  background-color: #f3f5f7;
  background-image: -webkit-linear-gradient(top, #f3f5f7, #fff);
  display: flex;
  justify-content: center;
  align-content: flex-start;
}


.address-search__title {
  color: #112950;
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 20px 0;
  letter-spacing: -.5px;
  font-size: 47px;
    letter-spacing: -.796px;
}
