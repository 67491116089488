@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?jw1i73');
  src:  url('fonts/icomoon.eot?jw1i73#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?jw1i73') format('truetype'),
    url('fonts/icomoon.woff?jw1i73') format('woff'),
    url('fonts/icomoon.svg?jw1i73#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add_icon:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e901";
}
.icon-delete_icon:before {
  content: "\e902";
}
.icon-drop_down:before {
  content: "\e903";
}
.icon-edit_icon:before {
  content: "\e904";
}
.icon-radio_check_icon:before {
  content: "\e905";
}
.icon-radio_uncheck_icon:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e907";
}
.icon-menu:before {
  content: "\e908";
}
