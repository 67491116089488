@font-face {
  font-family: "inter-ui";
  font-weight: 300;
  src: url(/static/media/Inter-Light-BETA.42922cef.woff2) format("woff2"), url(/static/media/Inter-Light-BETA.235041ef.woff) format("woff");
}
@font-face {
  font-family: "inter-ui";
  font-weight: 400;
  src: url(/static/media/Inter-Regular.bffaed79.woff2) format("woff2"), url(/static/media/Inter-Regular.aebfbb3c.woff) format("woff");
}
@font-face {
  font-family: "inter-ui";
  font-weight: 500;
  src: url(/static/media/Inter-Medium.c09fb389.woff) format("woff2"), url(/static/media/Inter-Medium.2e5e0884.woff2) format("woff");
}
@font-face {
  font-family: "inter-ui";
  font-weight: 600;
  src: url(/static/media/Inter-SemiBold.acc6eebd.woff) format("woff2"), url(/static/media/Inter-SemiBold.cd04cacd.woff2) format("woff");
}
@font-face {
  font-family: "inter-ui";
  font-weight: 700;
  src: url(/static/media/Inter-Bold.61c493e3.woff) format("woff2"), url(/static/media/Inter-Bold.34356f6b.woff2) format("woff");
}
@font-face {
  font-family: "inter-ui";
  font-weight: 800;
  src: url(/static/media/Inter-ExtraBold.0b03428a.woff) format("woff2"), url(/static/media/Inter-ExtraBold.a627e2ce.woff2) format("woff");
}
h1, h2, h3, h4, h5 {
  color: #112950;
}

h1.page-title {
  color: #000;
  font-size: 54px;
  font-weight: 700;
  margin: 0 0 15px 0;
  letter-spacing: -0.8px;
  display: block;
}

p.page-subtitle {
  line-height: 1.75rem;
  display: block;
  letter-spacing: -0.1px;
  color: #000;
  font-size: 24px;
  margin: 0 0 42px 0;
  font-weight: 400;
}

textarea {
  width: 100%;
  resize: none;
  padding: 15px;
  overflow: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /*
  IE10+ specific styles go here
  make it super specific so it overrides other styles
  */
  html body .main-template__home-screen .content .container,
html body .main-template__sign-in-screen .content .container,
html body .main-template__issue-detail-screen .content .container,
html body .main-template__new-issue-screen .content .container,
html body .main-template__service-request-screen .content .container {
    margin: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  html body .DateInput_input,
html body .input,
html body .react-autosuggest__input,
html body .DateInput_input.input--large,
html body .input.input--large,
html body .react-autosuggest__input.input--large {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  html body .issue-search-form__input, html body .issue-search-form__input.input {
    padding-top: 25px;
    padding-bottom: 25px;
    line-height: 1em;
  }
  html body .language-picker select {
    font-size: 0px;
    display: block;
    line-height: 0px;
  }
}
html,
body {
  font-size: 16px;
  height: 100%;
  width: 100%;
  background-color: #fff;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

#root {
  height: inherit;
  min-height: 100%;
}

.root__navigation {
  background-color: #fff;
  background: #2653ca;
  z-index: 2;
}

.root__content {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media (min-width: 0px) and (max-width: 700px) {
  .root__content {
    margin-left: 0;
    width: 100%;
    height: 100%;
    padding-top: 180px;
  }
}

.max-width {
  width: 1000px;
  padding: 0;
  max-width: 100vw;
  margin: 0 auto;
}
@media (min-width: 0px) and (max-width: 900px) {
  .max-width {
    display: block;
    width: 100%;
    padding: 20px;
  }
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "inter-ui";
  font-weight: 400;
}

.section-header {
  color: #112950;
  color: #424242;
  color: #112b4e;
  font-size: 22px;
  font-weight: 600;
  line-height: 38px;
  text-transform: capitalize;
  width: 100%;
  letter-spacing: -0.3px;
  margin: 0;
}

.section-subheader,
.section-label {
  color: #112950;
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  overflow-wrap: break-word;
}
.section-subheader span.hint,
.section-label span.hint {
  display: block;
  font-weight: 400;
  line-height: 1.5;
  font-size: 17px;
  margin-top: 2px;
  color: #646271;
  max-width: 650px;
}
.section-subheader span.time,
.section-label span.time {
  padding-left: 20px;
  display: block;
}
@media (min-width: 990px) {
  .section-subheader span.time,
.section-label span.time {
    display: inline;
  }
}

.section-subheader--inprogress {
  color: #25b429;
}

hr {
  border: 0;
  border-top: 1px solid #dde0e4;
  margin: 50px 0 0;
}

.mt-40 {
  margin-top: 40px;
}

.section-thumbnails {
  width: 57px;
}

.navbar-brand-issue {
  color: #000;
  font-size: 14px;
}
@media (min-width: 990px) {
  .navbar-brand-issue {
    font-size: 22px;
  }
}

.btn__btnback {
  display: inline-flex;
}
.btn__btnback .btn {
  padding: 0px;
  min-width: 50px;
}
@media (min-width: 991px) {
  .btn__btnback .btn {
    padding: 4px;
    min-width: 100px;
  }
}

.text-danger {
  display: block;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  top: -20px;
}

@media (min-width: 576px) and (max-width: 767px) {
  .col-50 {
    max-width: 49.5% !important;
    flex: 0 0 49.5%;
  }
}

.DateInput_input__focused {
  border: none !important;
}

.DateInput_fang {
  top: 48px !important;
}

/* IE11 Hack */
*::-ms-backdrop,
.DateInput_fang {
  top: 44px !important;
}

.page-header {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 15px;
  letter-spacing: -0.4px;
}

.page-subheader {
  color: #ffffffb5;
  font-size: 18px;
  font-weight: 400;
}

.paper-container {
  margin-top: 25px;
  background-color: white;
  border-radius: 4px;
  padding: 20px 15px;
  position: relative;
  text-decoration: none;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
  padding: 0;
  overflow: hidden;
}

.paper-container-header {
  background: #d5dae4;
  display: block;
  font-weight: 600;
  padding-left: 24px;
  margin: 0;
}
.paper-container-header h3 {
  line-height: 54px;
  font-size: 18px;
  color: #00000080;
  margin: 0;
  padding: 0;
}

.form-section {
  padding: 20px 25px;
}

.textarea-fadein {
  animation: textareaEntryAnimation 400ms;
}

@keyframes textareaEntryAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.paper {
  background: #eeeeee;
  margin: 0 auto 30px auto;
  width: 100%;
  padding: 2.1875rem 2.5rem;
}
.paper h3 {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.5px;
}
.paper h1 {
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 2.25rem;
  letter-spacing: -0.5px;
  margin-bottom: 0.75rem;
}
.paper h2 {
  color: #939598;
  font-weight: 400;
  font-size: 1.125rem;
  display: block;
  text-align: center;
  margin-bottom: 1.875rem;
}
@media (max-width: 580px) {
  .paper {
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding: 1.25rem 0.9375rem;
  }
  .paper h1 {
    text-align: left;
    font-size: 1.625rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    margin-bottom: 0.5rem;
  }
  .paper h2 {
    text-align: left;
    font-size: 0.9375rem;
    margin-bottom: 1.875rem;
  }
}

button.submit-button {
  background-color: #132e71;
  border: 0;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  line-height: 56px;
  outline: none;
  padding: 0 32px;
  text-decoration: none;
  max-width: 250px;
  margin: 30px 0 0 0;
  background-color: #5de8c1;
  background-color: #077284;
  border-radius: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.sub-input-container {
  padding-left: 20px;
  margin-left: 12px;
  border-left: 1px solid #2e5fdd;
  margin-bottom: 30px;
}

.left-right-form-items {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
}
.left-right-form-items > div {
  width: 50%;
}
.left-right-form-items > div:nth-child(1) {
  padding-right: 25px;
}
.left-right-form-items > div:nth-child(2) {
  padding-left: 25px;
}
@media (min-width: 0px) and (max-width: 700px) {
  .left-right-form-items {
    display: block;
  }
  .left-right-form-items > div {
    width: 100%;
  }
  .left-right-form-items > div:nth-child(1) {
    padding-right: 0;
  }
  .left-right-form-items > div:nth-child(2) {
    padding-left: 0;
  }
}

.radio__option-label,
.checkbox__option-label {
  color: #112950;
}

.hamburger-menu {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.hamburger-menu i {
  color: #fff;
  font-size: 30px;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  min-height: 100%;
}

.content {
  flex: 1 1 auto;
  width: 100%;
  min-height: auto;
  background-color: #f3f5f7;
  background-image: -webkit-linear-gradient(top, #f3f5f7, #fff);
  display: flex;
  justify-content: center;
  align-content: flex-start;
}

.address-search__title {
  color: #112950;
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 20px 0;
  letter-spacing: -0.5px;
  font-size: 47px;
  letter-spacing: -0.796px;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_bZF3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_bZF3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_bZF3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_c5H3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_c5H3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_c5H3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_c5H3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_c5H3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.28cd09c9.eot);
  src:  url(/static/media/icomoon.28cd09c9.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.fbc7b8a4.ttf) format('truetype'),
    url(/static/media/icomoon.74d13b12.woff) format('woff'),
    url(/static/media/icomoon.be726b05.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add_icon:before {
  content: "\E900";
}
.icon-calendar:before {
  content: "\E901";
}
.icon-delete_icon:before {
  content: "\E902";
}
.icon-drop_down:before {
  content: "\E903";
}
.icon-edit_icon:before {
  content: "\E904";
}
.icon-radio_check_icon:before {
  content: "\E905";
}
.icon-radio_uncheck_icon:before {
  content: "\E906";
}
.icon-search:before {
  content: "\E907";
}
.icon-menu:before {
  content: "\E908";
}

