@import './variables.scss';

@font-face {
  font-family: 'inter-ui';
  font-weight: 300;
  src: url('../assets/fonts/interui/Inter-Light-BETA.woff2') format('woff2'),
       url('../assets/fonts/interui/Inter-Light-BETA.woff') format('woff');
}

@font-face {
  font-family: 'inter-ui';
  font-weight: 400;
  src: url('../assets/fonts/interui/Inter-Regular.woff2') format('woff2'),
       url('../assets/fonts/interui/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'inter-ui';
  font-weight: 500;
  src: url('../assets/fonts/interui/Inter-Medium.woff') format('woff2'),
       url('../assets/fonts/interui/Inter-Medium.woff2') format('woff');
}

@font-face {
  font-family: 'inter-ui';
  font-weight: 600;
  src: url('../assets/fonts/interui/Inter-SemiBold.woff') format('woff2'),
       url('../assets/fonts/interui/Inter-SemiBold.woff2') format('woff');
}

@font-face {
  font-family: 'inter-ui';
  font-weight: 700;
  src: url('../assets/fonts/interui/Inter-Bold.woff') format('woff2'),
       url('../assets/fonts/interui/Inter-Bold.woff2') format('woff');
}

@font-face {
  font-family: 'inter-ui';
  font-weight: 800;
  src: url('../assets/fonts/interui/Inter-ExtraBold.woff') format('woff2'),
       url('../assets/fonts/interui/Inter-ExtraBold.woff2') format('woff');
}



h1, h2, h3, h4, h5 {
  color: $color-title;
}

h1.page-title {
  color: #000;
  font-size: 54px;
  font-weight: 700;
  margin: 0 0 15px 0;
  letter-spacing: -0.8px;
  display: block;
}

p.page-subtitle {
  line-height: 1.75rem;
  display: block;
  letter-spacing: -.1px;
  color: #000;
  font-size: 24px;
  margin: 0 0 42px 0;
  font-weight: 400;
}
